import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import FeaturedPostsGrid from "./FeaturedPostsGrid";
import PortableText from "./portableText";
import { MaxWidth, SmallText, PageDivider, HideOnMobile } from "./ui-components";
import styled from "styled-components";

const AuthorTopBlock = styled.div`
  background-color: #035642;
  margin-top: -1.25rem;

  -webkit-box-shadow: inset 0px 4px 12px -4px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0px 4px 12px -4px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 4px 12px -4px rgba(0, 0, 0, 0.6);

  color: white;

  padding: 2rem 0;
`;

const AuthorHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  img {
    border-radius: 100%;
    margin-bottom: 0;
  }
`;

const AuthorName = styled.h1`
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
`;

const AuthorBio = styled.div`
  p {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 600px) {
    p {
      text-align: center;
    }
  }
`;

export default function AuthorPage(props) {
  const { image, name, slug, _rawBio, newestPosts } = props;

  return (
    <>
      <AuthorTopBlock>
        <MaxWidth>
          <AuthorHeaderWrapper>
            <div>
              <AuthorName>{name}</AuthorName>
              <AuthorBio>{_rawBio && <PortableText blocks={_rawBio} />}</AuthorBio>
            </div>
            <div style={{ width: "100px" }}>
              <Img fixed={image.asset.fixed} alt={`${name} Logo`} />
            </div>
          </AuthorHeaderWrapper>
        </MaxWidth>
      </AuthorTopBlock>
      <div style={{ marginTop: "1.25rem" }}>
        <MaxWidth>
          {newestPosts && (
            <FeaturedPostsGrid
              firstRow={"two-columns"}
              secondRow={"three-columns"}
              posts={newestPosts}
              onAuthorPage={true}
            />
          )}
          <HideOnMobile>
            <PageDivider />
          </HideOnMobile>
          <SmallText>
            These are the latest stories from {name}. You can see more stories by browsing our{" "}
            <Link to="/archive/">archive</Link>.
          </SmallText>
        </MaxWidth>
      </div>
    </>
  );
}

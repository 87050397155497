import React from "react";
import { graphql } from "gatsby";
import AuthorPage from "../components/AuthorPage";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText } from "../lib/helpers";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";

export const query = graphql`
  query AuthorPageTemplateQuery($id: String!) {
    site {
      siteMetadata {
        siteUrl
        siteMetaTitle
      }
    }
    author: sanityAuthor(id: { eq: $id }) {
      image {
        asset {
          url
          fixed(width: 100) {
            ...GatsbySanityImageFixed_noBase64
          }
        }
      }
      id
      name
      slug {
        current
      }
      _rawBio(resolveReferences: { maxDepth: 5 })
    }
    newestPosts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        authors: { elemMatch: { author: { id: { eq: $id } } } }
      }
      limit: 5
    ) {
      edges {
        node {
          _id
          title
          publishedAt(formatString: "MMM DD, YYYY")
          slug {
            current
          }
          mainImage {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid
              }
            }
          }
          authors {
            author {
              id
              name
              image {
                asset {
                  fixed(width: 36) {
                    ...GatsbySanityImageFixed_noBase64
                  }
                }
              }
              slug {
                current
              }
            }
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

const BlogPostTemplate = (props) => {
  const { data, location } = props;
  const author = data && data.author;

  const newestPosts = (data || {}).newestPosts
    ? mapEdgesToNodes(data.newestPosts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout location={location.pathname}>
      {author && (
        <SEO
          title={`${author.name}` || "Untitled"}
          description={`${author.name} || ${toPlainText(author._rawBio)}`}
          image={author.image.asset.url}
        />
      )}
      {author && <AuthorPage {...author} newestPosts={newestPosts} />}
    </Layout>
  );
};

export default BlogPostTemplate;
